"use client";

import { FeatureFlags } from "@prisma/client";
import React, { createContext, useEffect, useState } from "react";

export interface FeatureFlagsProviderType {
  featureFlags: FeatureFlags[];
}

export const FeatureFlagsContext = createContext<FeatureFlagsProviderType>({
  featureFlags: [],
});

interface ProviderProps {
  featureFlags: FeatureFlags[];
  children: React.ReactNode;
}

export const FeatureFlagsProvider = ({ children, featureFlags }: ProviderProps) => {
  const [featureFlagsContext, setFeatureFlagsContext] = useState<FeatureFlagsProviderType>({
    featureFlags: featureFlags,
  });

  useEffect(() => {
    setFeatureFlagsContext({
      featureFlags: featureFlags,
    });
  }, [featureFlags]);

  return <FeatureFlagsContext.Provider value={featureFlagsContext}>{children}</FeatureFlagsContext.Provider>;
};
