"use client";

import { PlanContext, initialPlanValues } from "@/app/contexts";
import AppLayout from "@/components/Layout/layout";

import { ChakraProvider } from "@chakra-ui/react";

import { useState } from "react";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { SessionProvider } from "next-auth/react";
import AuthContext from "./authContext";

import { PaginationProvider } from "@/hooks/usePagination";
import { User } from "@/services/server/data/UserData.service";
import antTheme from "@/theme/antTheme";
import chakraTheme from "@/theme/chakraTheme";
import { Companies, CompanySubscriptions, FeatureFlags, Users } from "@prisma/client";
import { ConfigProvider } from "antd";
import { Session } from "next-auth";
import { FeatureFlagsProvider } from "./contexts/featureFlagsContext";

const Providers = ({
  children,
  session,
  user,
  featureFlags,
  company,
  subscriptions,
}: {
  children: React.ReactNode;
  session: Session | null;
  user: User | null;
  company: Companies | null;
  featureFlags: FeatureFlags[];
  subscriptions: CompanySubscriptions[] | null;
}) => {
  const [plan, setPlan] = useState(initialPlanValues);
  const [client] = useState(new QueryClient({ defaultOptions: { queries: { staleTime: 5000 } } }));

  return (
    <ConfigProvider theme={antTheme}>
      <ChakraProvider theme={chakraTheme} cssVarsRoot="html">
        <SessionProvider session={session} refetchInterval={60} refetchOnWindowFocus={true}>
          <PlanContext.Provider value={{ plan, setPlan }}>
            <QueryClientProvider client={client}>
              <AuthContext user={user} company={company} subscriptions={subscriptions}>
                <FeatureFlagsProvider featureFlags={featureFlags}>
                  <PaginationProvider>
                    <AppLayout>{children}</AppLayout>
                  </PaginationProvider>
                </FeatureFlagsProvider>
              </AuthContext>
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </PlanContext.Provider>
        </SessionProvider>
      </ChakraProvider>
    </ConfigProvider>
  );
};

export default Providers;
