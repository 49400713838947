import { CurrentUserContext } from "@/app/contexts/currentUserContext";
import { NavButton } from "@/app/settings/NavButton";
import LogoButton from "@/components/brand/LogoButton";
import { SubscriptionType } from "@/models";
import { White_color } from "@/theme/antTheme";
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { CogIcon } from "@heroicons/react/24/outline";
import { BiSitemap } from "@react-icons/all-files/bi/BiSitemap";
import { FaUserCog } from "@react-icons/all-files/fa/FaUserCog";
import { FiHelpCircle } from "@react-icons/all-files/fi/FiHelpCircle";
import { MdAdminPanelSettings } from "@react-icons/all-files/md/MdAdminPanelSettings";
import { MdLogout } from "@react-icons/all-files/md/MdLogout";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useContext } from "react";
import { CiImport } from "react-icons/ci";

const { version } = require("@root/package.json");

interface NavigationItem {
  name: string;
  href: string;
  icon: any;
  requiresAdmin?: boolean;
  requiresSuperAdmin?: boolean;
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const SideBarMenu = ({ showSidebarMenu, sidebarOpen, setSidebarOpen, signOut, isMobile }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const path = usePathname();
  const { currentUser, isSuperAdmin, activeSubscription } = useContext(CurrentUserContext);
  const hasEnterprise = activeSubscription?.type === SubscriptionType.Enterprise;
  const hasPro = activeSubscription?.type === SubscriptionType.Pro;

  const appNavigation = [
    {
      name: "Projects",
      href: "/projects",
      icon: BiSitemap,
      requiresAdmin: false,
    },
  ];

  const settingsNavigation = [
    {
      name: "User Settings",
      href: "/profile/user",
      icon: FaUserCog,
      requiresAdmin: false,
    },
    {
      name: "Company Settings",
      href: "/settings/profile/company",
      icon: CogIcon,
      requiresAdmin: true,
    },
    {
      name: "Condoit Admin",
      href: "/admin/companies?page=1&limit=10",
      icon: MdAdminPanelSettings,
      requiresSuperAdmin: true,
    },
  ];

  if (hasEnterprise || hasPro) {
    settingsNavigation.push({
      name: "Import",
      href: "/import",
      icon: CiImport,
      requiresAdmin: true,
    });
  }

  const BottomSettings = () => {
    return (
      <Stack spacing={{ base: "5", sm: "6" }} px="4" pb={5}>
        <Stack spacing="1">
          <NavButton
            _hover={{
              bg: "none",
              color: "inherit",
              boxShadow: "none",
            }}
            width={"full"}
            size={"md"}
            label="Support"
            icon={FiHelpCircle}
            color={White_color}
            onClick={onOpen}
          />
          <NavButton
            _hover={{
              bg: "none",
              color: "inherit",
              boxShadow: "none",
            }}
            size={"md"}
            label="Sign Out"
            icon={MdLogout}
            onClick={() => {
              signOut();
            }}
            color={White_color}
          />
          <Text fontSize={"sm"} color={White_color} textAlign={"center"}>
            v{version}
          </Text>
        </Stack>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              <Flex direction={"column"} gap={5} m={8}>
                <Text mb={8}>If you have any issues or questions with using Condoit, please contact us.</Text>
                <Button mb={3} onClick={() => (window.location.href = "mailto:support@condoit.io")}>
                  Email us
                </Button>

                <Link
                  href={"https://learn.condoit.io/knowledge"}
                  target={"_blank"}
                  prefetch={false}
                  style={{ width: "100%" }}
                >
                  <Button width={"full"}>Help Center</Button>
                </Link>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Stack>
    );
  };

  function isActiveRoute(routeHref) {
    // 1. Handle potential differences in trailing slashes:
    const normalizedHref = routeHref.endsWith("/") ? routeHref.slice(0, -1) : routeHref;
    const normalizedPathname = path.endsWith("/") ? path.slice(0, -1) : path;

    // 2. Check for an exact match:
    if (normalizedHref === normalizedPathname) {
      return true;
    }

    // 3. Check for nested route matching:
    return normalizedPathname.startsWith(normalizedHref);
  }

  const NavigationLink = ({ item }: { item: NavigationItem }) => {
    if (item.requiresAdmin && currentUser?.isAdmin !== true) {
      return null;
    }

    if (item.requiresSuperAdmin && !isSuperAdmin) {
      return null;
    }

    const active = isActiveRoute(item.href);

    function onClickNav() {
      if (isMobile) {
        setSidebarOpen(false);
      }
    }

    return (
      <Link
        key={item.name}
        href={item.href}
        prefetch={false}
        className={`group flex items-center rounded-md px-2 py-2 text-base font-medium`}
        onClick={onClickNav}
      >
        <item.icon
          color={active ? White_color : "#C5CCD3"}
          className={classNames("mr-4 h-6 w-6 flex-shrink-0")}
          aria-hidden="true"
        />
        <div style={active ? { color: White_color } : { color: "#C5CCD3" }}>
          {active ? (
            <Text color={White_color} fontWeight={"bold"}>
              {item.name}
            </Text>
          ) : (
            <Text color={"#C5CCD3"}>{item.name}</Text>
          )}
        </div>
      </Link>
    );
  };

  return (
    <div
      className="border-rpt-5 flex h-full flex-grow flex-col overflow-y-auto text-white"
      style={{
        fontSize: "1.2rem",
      }}
    >
      <div className="flex flex-shrink-0 items-center pr-4 pt-5">
        <LogoButton inverse />
      </div>

      <div className="mt-5 flex ">
        <nav className="mx-3 flex-1 space-y-1 border-b px-2 pb-2">
          {appNavigation.map((item, index) => (
            <NavigationLink key={item.name + index} item={item} />
          ))}
        </nav>
      </div>
      <div className="flex flex-grow pt-2">
        <nav className="mx-3 flex-1 space-y-1 px-2 pb-4">
          {settingsNavigation.map((item, index) => (
            <NavigationLink key={item.name + index} item={item} />
          ))}
        </nav>
      </div>
      <BottomSettings />
    </div>
  );
};

export default SideBarMenu;
