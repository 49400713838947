"use client";
/* eslint-disable @next/next/no-img-element */
import "@/styles/globals.css";

import { useContext, useEffect, useState } from "react";

import { PlanContext, initialPlanValues } from "@/app/contexts";
import { Flex } from "@chakra-ui/react";

import { usePathname, useRouter } from "next/navigation";

import { CurrentUserContext } from "@/app/contexts/currentUserContext";

import { useAuthenticator } from "@/hooks/useAuth";
import { uiEnvironment } from "@root/config/UIEnvironment.service";
import { useQueryClient } from "@tanstack/react-query";
import { App, Grid, Layout, theme } from "antd";
// import Header from "./Header";
import Sider from "antd/es/layout/Sider";
import { Content, Header } from "antd/es/layout/layout";
import { motion } from "framer-motion";
import { isNil } from "lodash";
import CondoitHeader from "./Header";
import SidebarMenu from "./SidebarMenu";

const { useBreakpoint } = Grid;

interface LayoutProps {
  children: React.ReactNode;
  signOut: any;
}

const SideMenuWidth = 260;

export const LayoutWrapper = ({ children, signOut }: LayoutProps) => {
  const breakpoints = useBreakpoint();
  const isMobile = breakpoints.md === false;
  const [sidebarOpen, setSidebarOpen] = useState(!isMobile);
  const { currentUser, currentCompany } = useContext(CurrentUserContext);
  const { authStatus } = useAuthenticator();
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const pathname = usePathname();

  if (uiEnvironment.PKEY && currentUser?.id && typeof window !== "undefined") {
    (window as any).pendo.initialize({
      visitor: {
        id: currentUser?.email,
        "First Name": currentUser?.firstName,
        "Last Name": currentUser?.lastName,
      },
      account: {
        id: currentCompany?.id,
        "Company Name": currentCompany?.name,
      },
    });
  }

  useEffect(() => {
    if (isMobile && sidebarOpen) {
      setSidebarOpen(false);
    } else {
      setSidebarOpen(true);
    }
  }, [breakpoints]);

  const getStartedRoute = pathname?.startsWith("/get-started");

  const showSidebarMenu = !getStartedRoute && !isNil(currentUser?.acceptedTerms);

  return showSidebarMenu ? (
    <>
      <Layout style={{ minHeight: "100vh", flexDirection: "row" }}>
        <motion.div
          animate={{
            x: 0,
            opacity: 1,
            transition: {
              duration: 0.5,
            },
          }}
          initial={{
            x: -250,
            opacity: 0,
          }}
          exit={{
            x: -250,
            opacity: 0,
            transition: {
              duration: 0.5,
            },
          }}
        >
          <Sider
            style={{
              overflow: "auto",
              height: "100vh",
              position: "fixed",
              left: 0,
              top: 0,
              bottom: 0,
            }}
            width={SideMenuWidth}
            collapsible={isMobile} // If screen is NOT of size medium, then it IS collapsible. aka it's a small screen.
            collapsed={!sidebarOpen}
            // onCollapse={(value) => setSidebarOpen(value)}
            trigger={null}
            collapsedWidth="0"
          >
            <SidebarMenu
              isMobile={isMobile}
              showSidebarMenu={sidebarOpen}
              sidebarOpen={sidebarOpen}
              setSidebarOpen={setSidebarOpen}
              signOut={signOut}
            />
          </Sider>
        </motion.div>
        <Layout
          style={{
            marginLeft: sidebarOpen ? SideMenuWidth : 0,
            maxWidth: sidebarOpen ? "calc(100vw - 260px)" : "100vw",
            zIndex: 2,
          }}
        >
          <motion.div
            animate={{
              y: 0,
              opacity: 1,
              transition: {
                duration: 0.5,
              },
            }}
            initial={{
              y: -50,
              opacity: 0,
            }}
            exit={{
              y: -50,
              opacity: 0,
              transition: {
                duration: 0.5,
              },
            }}
          >
            <Header className="background-white p-0" style={{ padding: 0, backgroundColor: "#ffffff" }}>
              <CondoitHeader showSidebarMenu={sidebarOpen} setSidebarOpen={setSidebarOpen} signOut={signOut} />
            </Header>
          </motion.div>
          <Content className="pt-5" style={isMobile ? undefined : { margin: "0 16px" }}>
            <div
              style={{
                minHeight: 360,
                padding: 14,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
                maxWidth: "100%",
                overflow: "auto",
              }}
            >
              {children}
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  ) : (
    <App>{children}</App>
  );
};

interface Props {
  children: React.ReactNode;
}

const AppLayout = (props: Props) => {
  // @ts-expect-error layout is a prototype property and dynamic
  const Layout = props.children?.type?.layout;
  const router = useRouter();
  const queryClient = useQueryClient();
  const path = usePathname();
  const { setPlan } = useContext(PlanContext);

  if (Layout != null) {
    return (
      <Flex width="100vw" height={"100vh"} px={0} m={0} p={0} justifyContent="center" alignItems={"center"}>
        {props.children}
      </Flex>
    );
  }

  return (
    <LayoutWrapper
      signOut={() => {
        router.push("/auth/signout");
        queryClient.clear();
        setPlan(initialPlanValues);
      }}
    >
      <App>{props.children}</App>
    </LayoutWrapper>
  );
};

export default AppLayout;
